import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  pageTitle: 'Business Developer / Sales Manager',
  introTitle: 'Business Developer / Sales Manager (m/w/d) - Software Development - in Vollzeit',
  introInfo: '<p><b>Die freshcells systems engineering GmbH ist ein Software-Dienstleister mit Sitz in Düsseldorf und einem dynamischen Team von über 50 kreativen Experten aus verschiedenen Bereichen.</b></p><p>Bei uns stehen <b>Authentizität, individuelle Talentförderung</b> und <b>offene Kommunikation</b> im Fokus. Wir bauen auf <b>Respekt, Vertrauen</b> und <b>Verantwortung</b>. Als <b>innovationsgetriebenes Unternehmen</b> investieren wir in <b>neue Ideen</b>. Wir wissen, dass die besten Ergebnisse im <b>Team</b> entstehen. <b>Fehler</b> machen wir, doch wir lernen daraus. Wir sind offen und mutig für <b>neue Wege</b>.</p><p>Unsere DNA ist es, <b>nachhaltige Lösungen</b> zu schaffen, mit dem <b>Anspruch auf Exzellenz</b>. Wir analysieren unsere Projekte im Vorhinein genau. Deshalb leisten wir, was wir verkaufen und <b>halten unsere Versprechen</b>. Unsere Beratung ist <b>ehrlich und kundenorientiert</b>.</p><p>Als Business Developer / Sales Manager wirst du Teil unseres New Business Teams und übernimmst den Lead in der initialen Kundenakquise.</p><p>Begleite uns auf unserer spannenden Reise im wohl bedeutendsten und zukunftsweisenden Bereich - der Software-Entwicklung - als <b>Business Developer / Sales Manager (m/w/d) - Software Development in Vollzeit!</b></p>',
  responsibilitiesTitle: 'Deine Aufgaben',
  responsibility1: 'Identifizierung von Neukunden und Generierung neuer Verkaufschancen',
  responsibility2: 'Erstellung von Präsentationen sowie Führung von Verkaufs- und Beratungsgesprächen, mit besonderem Fokus auf die Tourismusbranche',
  responsibility3: 'Begleitung der Neukunden vom Erstkontakt über die Verhandlung der geschäftlichen und vertraglichen Konditionen bis hin zum erfolgreichen Vertragsabschluss',
  responsibility4: 'Planung und Koordination der Projektprozesse für Neukunden in enger Zusammenarbeit mit dem Management und internen Abteilungen wie Development und Projektmanagement',
  responsibility5: 'Beobachtung von Markt- und Branchentrends sowie Identifizierung von Möglichkeiten zur Umsatzsteigerung durch technologische Innovationen',
  responsibility6: 'Erreichen nachweisbarer Erfolge in einem dynamisch wachsenden Geschäftsbereich',
  responsibility7: 'Vertretung von freshcells auf Fachmessen und relevanten Veranstaltungen zur Stärkung der Marktpräsenz',
  qualificationsTitle: 'Deine Qualifikation',
  qualification1: 'Mindestens drei Jahre nachweisbare Erfolge im Vertrieb von komplexen Softwarelösungen',
  qualification2: 'Hohe IT-Affinität und bestehendes Interesse, sich intensiv in anspruchsvolle Softwarelösungen einzuarbeiten und diese zu verstehen',
  qualification3: 'Kenntnisse in der Touristikbranche sind von Vorteil, aber keine zwingende Voraussetzung',
  qualification4: 'Stark ausgeprägtes kaufmännisches Verständnis mit nachgewiesener Erfahrung im Lösungsverkauf sowie hervorragende Präsentations-, Verhandlungs- und Problemlösungsfähigkeiten',
  qualification5: 'Hervorragende Kommunikationsfähigkeiten in Deutsch und Englisch',
  qualification6: 'Persönliche Stärken wie eine eigenverantwortliche Arbeitsweise, positive Ausstrahlung und sicheres Auftreten',
  qualification7: 'Bereitschaft zu Reisen'
})
